import { useForm } from '@refinedev/antd'
import { useCreate, useDelete, useList, useUpdate } from '@refinedev/core'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd'
import type { ICourtPatchRequest, ICourtResponse, ISport } from 'interfaces'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { CourtSlotType } from 'utils/enums'
import { ActionsWrapper } from './actionsWrapper'
import { dayOfWeekOptions, timeOptions } from './data'

export const Court: FC<{
  court: ICourtResponse
  allCourtsAtLocation?: ICourtResponse[]
}> = ({ court, allCourtsAtLocation }) => {
  const { form, formProps } = useForm<ICourtResponse['slots' | 'sports']>()
  const { form: formSlotModal, formProps: formSlotModalProps } = useForm()
  const { form: formSportModal, formProps: formSportModalProps } = useForm()
  const { mutate: mutateUpdate } = useUpdate()
  const { mutate: mutateDelete } = useDelete()
  const { mutate: mutateCreate } = useCreate()

  useEffect(() => {
    form.resetFields()
    formSlotModal.resetFields()
    formSportModal.resetFields()
  }, [court])

  const [isSlotModalOpen, setIsSlotModalOpen] = useState(false)
  const [isSportModalOpen, setIsSportModalOpen] = useState(false)

  const courtOverlaps = useMemo(() => {
    return (allCourtsAtLocation ?? []).reduce(
      (courtOverlapDetails: { [courtId: string]: string[] }) => {
        const overlappingCourtIds = court.overlaps.map((court) => court.id)

        return {
          ...courtOverlapDetails,
          [court.id]: overlappingCourtIds,
        }
      },
      {}
    )
  }, [allCourtsAtLocation])

  const [courtOverlapDetails, setCourtOverlapDetails] = useState(courtOverlaps)

  // fetch all sports:
  const { data: sportsData } = useList<ISport>({
    resource: 'sports',
  })

  const showSlotModal = () => {
    setIsSlotModalOpen(true)
  }

  const showSportModal = () => {
    setIsSportModalOpen(true)
  }

  const handleOkAddSlot = () => {
    const values: any = formSlotModal.getFieldsValue()

    mutateCreate(
      {
        invalidates: ['all'],
        resource: `courts/${court.id}/slots`,
        values: {
          dayOfWeek: values.dayOfWeek,
          openFrom: values.openFrom,
          openTo: values.openTo,
          type: values.type,
        },
      },
      {
        onSuccess: () => {
          setIsSlotModalOpen(false)
        },
      }
    )
  }

  const handleAddSport = () => {
    const values: any = formSportModal.getFieldsValue()

    mutateCreate(
      {
        invalidates: ['all'],
        resource: `courts/${court.id}/sports`,
        values: {
          price: Number(values.price),
          sportId: values.sportId,
        },
      },
      {
        onSuccess: () => {
          setIsSportModalOpen(false)
        },
      }
    )
  }

  const handleCancelSlotModal = () => {
    setIsSlotModalOpen(false)
  }

  const handleCancelSportModal = () => {
    setIsSportModalOpen(false)
  }

  const handleCourtDelete = () => {
    mutateDelete({
      id: court.id,
      invalidates: ['all'],
      resource: 'courts',
    })
  }

  const handleSlotDelete = (id: string) => {
    mutateDelete({
      id,
      invalidates: ['all'],
      resource: 'courts/slots',
    })
  }

  const handleSportDelete = (sportId: string) => {
    mutateDelete({
      resource: `courts/${court.id}/sports/${sportId}`,
      id: '',
      invalidates: ['all'],
    })
  }

  const handleCourtUpdate = () => {
    const values = form.getFieldsValue() as ICourtResponse
    const slots = values.slots
    const sports = values.sports
    const allowPrivateLessons = values.allowPrivateLessons

    const sanitizedSlots: ICourtPatchRequest['slots'] = slots.map((value) => ({
      dayOfWeek: value.dayOfWeek,
      id: value.id,
      openFrom: value.openFrom,
      openTo: value.openTo,
      type: value.type,
    }))

    const sanitizedSports: ICourtPatchRequest['sports'] = sports.map(
      (value) => ({
        price: Number(value.price),
        id: value.id,
      })
    )

    if (values?.reservationReleaseTimeLocal === '') {
      values.reservationReleaseTimeLocal = null
    }

    const sanitizedValues = {
      maxReservationTime: values.maxReservationTime,
      slots: sanitizedSlots,
      sports: sanitizedSports,
      noReservationText: values.noReservationText,
      allowPrivateLessons,
      overlappingCourtIds: courtOverlapDetails[court.id],
      defaultReservationWindowDays: values.defaultReservationWindowDays,
      reservationReleaseTimeLocal: values.reservationReleaseTimeLocal,
      courtNumber: values.courtNumber,
    }

    mutateUpdate({
      id: court.id,
      invalidates: ['all'],
      resource: `courts`,
      values: sanitizedValues,
    })
  }

  return (
    <>
      <Card
        actions={[
          <ActionsWrapper>
            <Button size="small" type="primary" onClick={handleCourtUpdate}>
              Update court
            </Button>
          </ActionsWrapper>,
        ]}
        extra={
          <ActionsWrapper>
            <Button danger size="small" onClick={handleCourtDelete}>
              Delete court #{court.courtNumber}
            </Button>
            <Button
              key="add-slot"
              size="small"
              type="primary"
              onClick={showSlotModal}
            >
              Add Slot
            </Button>
            <Button
              type="primary"
              size="small"
              title="Add Sport"
              key="add-sport"
              onClick={showSportModal}
            >
              Add Sport
            </Button>
          </ActionsWrapper>
        }
        key={court.id}
        style={{ marginTop: 32 }}
        title={`Court #${court.courtNumber}`}
      >
        <Form
          {...formProps}
          initialValues={{
            maxReservationTime: court.maxReservationTime,
            slots: court.slots,
            sports: court.sports,
            noReservationText: court.noReservationText,
            allowPrivateLessons: court.allowPrivateLessons,
            defaultReservationWindowDays: court.defaultReservationWindowDays,
            reservationReleaseTimeLocal: court.reservationReleaseTimeLocal,
            courtNumber: court.courtNumber,
          }}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Court Number"
                name="courtNumber"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* TODO:REC-3136 Remove once court.maxReservationTime is nullable */}
              <Form.Item
                hidden
                label="Max Reservation Time"
                name="maxReservationTime"
                rules={[
                  {
                    message: 'Please use HH:MM:SS format (e.g. 01:30:45)',
                    pattern: /^\d{2}:\d{2}:\d{2}$/,
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Reservation Window Override (days)"
                name="defaultReservationWindowDays"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                label="Reservation Release Time Override"
                name="reservationReleaseTimeLocal"
                rules={[
                  {
                    message:
                      'Invalid input. Must use 24-hour HH:MM:SS format (e.g. 13:30:00 for 1:30 PM)',
                    pattern: /^\d{2}:\d{2}:\d{2}$/,
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="No reservation text" name="noReservationText">
                <Input />
              </Form.Item>
              <Form.Item
                label="Allow Private Lessons"
                name="allowPrivateLessons"
              >
                <Switch defaultChecked={court.allowPrivateLessons ?? false} />
              </Form.Item>
            </Col>
          </Row>

          <div>
            <Divider />

            <h4>Overlapping Courts</h4>
            <div>
              Does court {court.courtNumber} overlap any other courts?{' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 80,
                }}
              >
                {(allCourtsAtLocation ?? [])
                  .filter(({ id }) => id !== court.id)
                  .map((otherCourt) => {
                    const overlappingCourtIds = courtOverlapDetails[court.id]

                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{otherCourt.courtNumber}</span>

                        <Switch
                          style={{ margin: 4 }}
                          defaultChecked={overlappingCourtIds.includes(
                            otherCourt.id
                          )}
                          onChange={(checked) => {
                            if (
                              checked &&
                              !overlappingCourtIds.includes(otherCourt.id)
                            ) {
                              setCourtOverlapDetails((prev) => ({
                                ...prev,
                                [court.id]: [...prev[court.id], otherCourt.id],
                              }))
                            }

                            if (!checked) {
                              setCourtOverlapDetails((prev) => ({
                                ...prev,
                                [court.id]: prev[court.id].filter(
                                  (id) => id !== otherCourt.id
                                ),
                              }))
                            }
                          }}
                        />
                      </div>
                    )
                  })}
              </div>
            </div>
            <Divider />
          </div>

          {
            // display sports
          }
          <Form.List name="sports">
            {(sportFields) => (
              <div>
                {sportFields.map((sportField) => {
                  return (
                    <Row align="middle" gutter={8} key={sportField.key}>
                      <Col span={4}>
                        <Form.Item
                          {...sportField}
                          label="Sport"
                          name={[sportField.key, 'sportId']}
                        >
                          <Typography.Text>
                            {
                              // find sportId in sportData and display sport name
                              sportsData?.data.find(
                                (sport) =>
                                  sport.id ===
                                  court.sports[sportField.key]?.sportId
                              )?.name
                            }
                          </Typography.Text>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...sportField}
                          label={`Price in ${
                            court.sports[sportField.key]?.currency
                          } cents`}
                          name={[sportField.key, 'price']}
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          danger
                          size="small"
                          onClick={() =>
                            handleSportDelete(
                              court.sports[sportField.key].sportId
                            )
                          }
                        >
                          Remove sport
                        </Button>
                      </Col>
                    </Row>
                  )
                })}
              </div>
            )}
          </Form.List>

          <Form.List name="slots">
            {(slotFields) => (
              <div>
                {slotFields.map((slotField) => {
                  return (
                    <Row align="middle" gutter={8} key={slotField.key}>
                      <Col span={4}>
                        <Form.Item
                          {...slotField}
                          label="Day of week"
                          name={[slotField.key, 'dayOfWeek']}
                        >
                          <Select options={dayOfWeekOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...slotField}
                          label="Open from"
                          name={[slotField.key, 'openFrom']}
                        >
                          <Select options={timeOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...slotField}
                          label="Open to"
                          name={[slotField.key, 'openTo']}
                        >
                          <Select options={timeOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...slotField}
                          label="Type"
                          name={[slotField.key, 'type']}
                        >
                          <Select
                            options={[
                              {
                                label: CourtSlotType.INSTRUCTOR,
                                value: CourtSlotType.INSTRUCTOR,
                              },
                              {
                                label: CourtSlotType.PRIVATE,
                                value: CourtSlotType.PRIVATE,
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          danger
                          size="small"
                          onClick={() =>
                            handleSlotDelete(court.slots[slotField.key].id)
                          }
                        >
                          Remove slot
                        </Button>
                      </Col>
                    </Row>
                  )
                })}
              </div>
            )}
          </Form.List>
        </Form>
      </Card>
      <Modal
        open={isSlotModalOpen}
        title="Add Slot"
        onCancel={handleCancelSlotModal}
        onOk={handleOkAddSlot}
      >
        <Form
          layout="vertical"
          {...formSlotModalProps}
          initialValues={{
            dayOfWeek: 1,
            openFrom: '08:00:00',
            openTo: '22:00:00',
            type: CourtSlotType.PRIVATE,
          }}
        >
          <Form.Item label="Day of week" name={['dayOfWeek']}>
            <Select options={dayOfWeekOptions} />
          </Form.Item>
          <Form.Item label="Open from" name={['openFrom']}>
            <Select options={timeOptions} />
          </Form.Item>
          <Form.Item label="Open to" name={['openTo']}>
            <Select options={timeOptions} />
          </Form.Item>
          <Form.Item label="Type" name={['type']}>
            <Select
              options={[
                {
                  label: CourtSlotType.INSTRUCTOR,
                  value: CourtSlotType.INSTRUCTOR,
                },
                {
                  label: CourtSlotType.PRIVATE,
                  value: CourtSlotType.PRIVATE,
                },
              ]}
              defaultValue={CourtSlotType.PRIVATE}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={isSportModalOpen}
        title="Add Sport"
        onCancel={handleCancelSportModal}
        onOk={handleAddSport}
      >
        <Form layout="vertical" {...formSportModalProps}>
          <Form.Item label="Sport" name={['sportId']}>
            <Select>
              {sportsData?.data.map((sport) => {
                return (
                  <Select.Option key={sport.id} value={sport.id}>
                    {sport.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Price" name={['price']}>
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
