import { Edit, getValueFromEvent, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useCustomMutation, useList, useUpdate } from '@refinedev/core'
import {
  Card,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tag,
  Upload,
} from 'antd'
import { useEffect, useState } from 'react'
import type {
  ILocation,
  IPreSignedRequest,
  IPreSignedResponse,
} from '../../interfaces'
import { IOrganization } from '../../interfaces'
import { Courts } from './courts/courts'
import { Map } from './map'
import { Regions } from './regions'
import TimezoneInput from './timezone-input'

export const LocationsEdit: React.FC<IResourceComponentsProps> = () => {
  const { mutateAsync } = useCustomMutation<IPreSignedResponse>()
  const [tagInput, setTagInput] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])
  const { form, formProps, queryResult, saveButtonProps } = useForm<ILocation>()
  const { mutate } = useUpdate<ILocation>({})
  const { data: organizations } = useList<IOrganization>({
    resource: 'organizations',
  })

  // use useEffect to set tags from query result
  useEffect(() => {
    if (queryResult?.data?.data?.tags) {
      setTags(queryResult?.data?.data?.tags)
    }
  }, [])

  const lat = Number(queryResult?.data?.data.lat ?? 0)
  const lng = Number(queryResult?.data?.data.lng ?? 0)

  const getUploadAction = async (
    args: File,
    type:
      | 'detail'
      | 'thumbnail'
      | 'pastReservation'
      | 'mainGallery'
      | 'smallGallery1'
      | 'smallGallery2'
  ) => {
    let { name: key, type: contentType } = args

    // make sure we always use jpeg, as content type for .jpg is image/jpeg
    if (key.split('.').pop() === 'jpg') key = key.replace('jpg', 'jpeg')

    const values: IPreSignedRequest = {
      contentType,
      key,
      type,
    }

    const url = `locations/${queryResult?.data?.data.id}/image`

    const { data } = await mutateAsync({
      method: 'post',
      url,
      values,
    })

    return data.preSignedUrl
  }

  const removeItemFromTags = (item: string) => {
    setTags(tags.filter((tag) => tag !== item))
  }

  const addItemToTags = (item: string) => {
    setTags([...tags, item])
  }

  const handleSubmit = (values: any) => {
    if (values?.reservationReleaseTimeLocal === '') {
      values.reservationReleaseTimeLocal = null
    }
    const sanitizedValues = {
      ...values,
      tags,
    }

    if (queryResult?.data?.data?.id) {
      mutate(
        {
          id: queryResult?.data?.data?.id,
          invalidates: ['all'],
          resource: 'locations',
          values: sanitizedValues,
        },
        {
          onSuccess: () => {
            form.resetFields()
          },
        }
      )
    }
  }

  return (
    <>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={saveButtonProps}
      >
        <Form
          {...formProps}
          initialValues={{
            isActive: true,
            ...formProps.initialValues,
          }}
          layout="vertical"
          style={{ marginTop: 30 }}
          onFinish={handleSubmit}
        >
          <>
            <Row gutter={20}>
              <Col lg={8} xs={24}>
                <Form.Item>
                  <Form.Item
                    noStyle
                    getValueFromEvent={getValueFromEvent}
                    valuePropName="fileList"
                  >
                    <Upload.Dragger
                      action={(args) => getUploadAction(args, 'detail')}
                      customRequest={(args) => {
                        const formData = new FormData()
                        formData.append('File', args.file)
                        const file = formData.get('File')

                        const xhr = new XMLHttpRequest()
                        xhr.open('PUT', args.action)
                        xhr.send(file)
                      }}
                      listType="picture"
                      maxCount={1}
                      method="PUT"
                      name="file"
                      style={{
                        background: 'none',
                        border: 'none',
                        width: '100%',
                      }}
                    >
                      <Space direction="vertical" size={2}>
                        <div>Location's hero (detail) image</div>
                        <Image
                          alt="Facility Location"
                          src={
                            queryResult?.data?.data.images.detail ??
                            '/images/user-default-img.png'
                          }
                          style={{
                            height: '100%',
                            maxWidth: '200px',
                            width: '100%',
                          }}
                        />
                      </Space>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
                <details>
                  <summary>Expand to see all images</summary>
                  <Form.Item>
                    <Form.Item
                      noStyle
                      getValueFromEvent={getValueFromEvent}
                      valuePropName="fileList"
                    >
                      <Upload.Dragger
                        action={(args) => getUploadAction(args, 'thumbnail')}
                        customRequest={(args) => {
                          const formData = new FormData()
                          formData.append('File', args.file)
                          const file = formData.get('File')

                          const xhr = new XMLHttpRequest()
                          xhr.open('PUT', args.action)
                          xhr.send(file)
                        }}
                        listType="picture"
                        maxCount={1}
                        method="PUT"
                        name="file"
                        style={{
                          background: 'none',
                          border: 'none',
                          width: '100%',
                        }}
                      >
                        <Space direction="vertical" size={2}>
                          <div>Thumbnail image</div>
                          <Image
                            alt="Facility Location"
                            src={
                              queryResult?.data?.data.images.thumbnail ??
                              '/images/user-default-img.png'
                            }
                            style={{
                              height: '100%',
                              maxWidth: '200px',
                              width: '100%',
                            }}
                          />
                        </Space>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      noStyle
                      getValueFromEvent={getValueFromEvent}
                      valuePropName="fileList"
                    >
                      <Upload.Dragger
                        action={(args) =>
                          getUploadAction(args, 'pastReservation')
                        }
                        customRequest={(args) => {
                          const formData = new FormData()
                          formData.append('File', args.file)
                          const file = formData.get('File')

                          const xhr = new XMLHttpRequest()
                          xhr.open('PUT', args.action)
                          xhr.send(file)
                        }}
                        listType="picture"
                        maxCount={1}
                        method="PUT"
                        name="file"
                        style={{
                          background: 'none',
                          border: 'none',
                          width: '100%',
                        }}
                      >
                        <Space direction="vertical" size={2}>
                          <div>Past reservation image</div>
                          <Image
                            alt="Facility Location"
                            src={
                              queryResult?.data?.data.images.pastReservation ??
                              '/images/user-default-img.png'
                            }
                            style={{
                              height: '100%',
                              maxWidth: '200px',
                              width: '100%',
                            }}
                          />
                        </Space>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      noStyle
                      getValueFromEvent={getValueFromEvent}
                      valuePropName="fileList"
                    >
                      <Upload.Dragger
                        action={(args) => getUploadAction(args, 'mainGallery')}
                        customRequest={(args) => {
                          const formData = new FormData()
                          formData.append('File', args.file)
                          const file = formData.get('File')

                          const xhr = new XMLHttpRequest()
                          xhr.open('PUT', args.action)
                          xhr.send(file)
                        }}
                        listType="picture"
                        maxCount={1}
                        method="PUT"
                        name="file"
                        style={{
                          background: 'none',
                          border: 'none',
                          width: '100%',
                        }}
                      >
                        <Space direction="vertical" size={2}>
                          <div>Main gallery image</div>
                          <Image
                            alt="Facility Location"
                            src={
                              queryResult?.data?.data.images.mainGallery ??
                              '/images/user-default-img.png'
                            }
                            style={{
                              height: '100%',
                              maxWidth: '200px',
                              width: '100%',
                            }}
                          />
                        </Space>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      noStyle
                      getValueFromEvent={getValueFromEvent}
                      valuePropName="fileList"
                    >
                      <Upload.Dragger
                        action={(args) =>
                          getUploadAction(args, 'smallGallery1')
                        }
                        customRequest={(args) => {
                          const formData = new FormData()
                          formData.append('File', args.file)
                          const file = formData.get('File')

                          const xhr = new XMLHttpRequest()
                          xhr.open('PUT', args.action)
                          xhr.send(file)
                        }}
                        listType="picture"
                        maxCount={1}
                        method="PUT"
                        name="file"
                        style={{
                          background: 'none',
                          border: 'none',
                          width: '100%',
                        }}
                      >
                        <Space direction="vertical" size={2}>
                          <div>Small gallery 1 image</div>
                          <Image
                            alt="Facility Location"
                            src={
                              queryResult?.data?.data.images.smallGallery1 ??
                              '/images/user-default-img.png'
                            }
                            style={{
                              height: '100%',
                              maxWidth: '200px',
                              width: '100%',
                            }}
                          />
                        </Space>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      noStyle
                      getValueFromEvent={getValueFromEvent}
                      valuePropName="fileList"
                    >
                      <Upload.Dragger
                        action={(args) =>
                          getUploadAction(args, 'smallGallery2')
                        }
                        customRequest={(args) => {
                          const formData = new FormData()
                          formData.append('File', args.file)
                          const file = formData.get('File')

                          const xhr = new XMLHttpRequest()
                          xhr.open('PUT', args.action)
                          xhr.send(file)
                        }}
                        listType="picture"
                        maxCount={1}
                        method="PUT"
                        name="file"
                        style={{
                          background: 'none',
                          border: 'none',
                          width: '100%',
                        }}
                      >
                        <Space direction="vertical" size={2}>
                          <div>Small gallery 2 image</div>
                          <Image
                            alt="Facility Location"
                            src={
                              queryResult?.data?.data.images.smallGallery2 ??
                              '/images/user-default-img.png'
                            }
                            style={{
                              height: '100%',
                              maxWidth: '200px',
                              width: '100%',
                            }}
                          />
                        </Space>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                </details>
              </Col>
              <Col lg={16} xs={24}>
                <Row gutter={10}>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label="Organization"
                      name="organizationId"
                      rules={[{ message: 'Please select organization' }]}
                    >
                      <Select>
                        <Select.Option value={undefined}>
                          No organization
                        </Select.Option>
                        {organizations?.data.map((organization) => (
                          <Select.Option value={organization.id}>
                            {organization.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.TextArea autoSize />
                    </Form.Item>
                    <TimezoneInput form={form} />
                    <Form.Item
                      label="Access info"
                      name="accessInfo"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.TextArea autoSize />
                    </Form.Item>
                    <Form.Item
                      label="Getting there info"
                      name="gettingThereInfo"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.TextArea autoSize />
                    </Form.Item>
                    <Form.Item
                      label="Default Reservation Window (in days)"
                      name="defaultReservationWindow"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                      label="Reservation release time (HH:mm:ss)"
                      name="reservationReleaseTimeLocal"
                      rules={[
                        {
                          required: false,
                          message:
                            'Invalid input. Must use 24-hour HH:MM:SS format (e.g. 13:30:00 for 1:30 PM)',
                          pattern: /^\d{2}:\d{2}:\d{2}$/,
                        },
                      ]}
                    >
                      <Input.TextArea autoSize />
                    </Form.Item>
                    <Form.Item
                      label="Private Lesson Reservation Window (in days)"
                      name="privateLessonReservationWindow"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                      label="Hours of Operation"
                      name="hoursOfOperation"
                    >
                      <Input.TextArea autoSize />
                    </Form.Item>
                    <Form.Item label="Schedule" name="schedule">
                      <Input.TextArea autoSize />
                    </Form.Item>
                    <Form.Item label="Play guidelines" name="playGuidelines">
                      <Input.TextArea autoSize />
                    </Form.Item>
                    <Form.Item
                      label="Tags (Press enter to add a new tag)"
                      name="tags"
                    >
                      <Input
                        value={tagInput}
                        onChange={(e) => setTagInput(e.currentTarget.value)}
                        onPressEnter={(e) => {
                          addItemToTags(e.currentTarget.value)
                          setTagInput('')
                        }}
                      />
                      {tags?.map((tag) => (
                        <Tag
                          closable
                          onClose={() => removeItemFromTags(tag)}
                          style={{ marginTop: 10 }}
                        >
                          {tag}
                        </Tag>
                      ))}
                    </Form.Item>
                    <Form.Item
                      label="No reservation text"
                      name="noReservationText"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Formatted address"
                      name="formattedAddress"
                      hidden
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      label="Longitude"
                      name="lng"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      label="Latitude"
                      name="lat"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      label="Place Id"
                      name="placeId"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="hidden" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
          <Card
            bodyStyle={{
              height: 550,
              padding: 0,
            }}
          >
            <Map
              center={{
                lat,
                lng,
              }}
              formattedAddress={queryResult?.data?.data.formattedAddress}
              getMapData={({ formattedAddress, lat, lng, placeId }) => {
                form.setFieldValue('formattedAddress', formattedAddress)
                form.setFieldValue('placeId', placeId)
                form.setFieldValue('lat', lat.toString())
                form.setFieldValue('lng', lng.toString())
              }}
              zoom={13}
            />
          </Card>
        </Form>
      </Edit>
      <Courts locationId={queryResult?.data?.data.id} />
      <Regions location={queryResult?.data?.data} />
    </>
  )
}
